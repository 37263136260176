import React, { FC, memo, useCallback, useMemo } from 'react';
import ListItem from '../../ui/ListItem';
import IconSvg from '../../ui/IconSvg';
import Avatar from '../../common/Avatar';
import classNames from 'classnames';
import {
  AiPrompt,
  AiPurchaseType,
  ISubsriptionItem,
} from '../../../global/types';
import { ApiChat } from '../../../api/types';
import { getActions, withGlobal } from '../../../global';
import { selectChat } from '../../../global/selectors';
import {
  getChatTitle,
  isChatAdult,
  isChatCourse,
  isChatSubscription,
} from '../../../global/helpers';
import { useTranslation } from 'react-i18next';
import { formatDateToString } from '../../../util/dateFormat';
import { getMoneyFormat } from '../../../util/convertMoney';
import useLastCallback from '../../../hooks/useLastCallback';
import IconSvgPayment from '../../payment/IconSvgPayment';
import useHistoryBack from '../../../hooks/useHistoryBack';
import { MiddleColumnContent } from '../../../types';

type StateProps = {
  chat?: ApiChat;
};

type OwnProps = {
  item: ISubsriptionItem;
  selectSubscription: (payload: {
    chatId: string;
    isActive: boolean;
    expire_at?: number;
    bot_type?: AiPurchaseType;
  }) => void;
  aiPrompts?: AiPrompt[];
  setActivePack: (pack: AiPrompt | undefined) => void;
};

const SettingsSubscriptionsItem: FC<OwnProps & StateProps> = ({
  item: {
    peer_id,
    is_active: active,
    cancelled,
    expire_at,
    amount,
    peer_type,
    bot_type,
  },
  chat,
  aiPrompts,
  selectSubscription,
  setActivePack,
}) => {
  const { openChat } = getActions();
  const { t } = useTranslation();

  const isChannelSubscription = useMemo(
    () => chat! && isChatSubscription(chat),
    [chat]
  );
  const aiName = useMemo(
    () =>
      peer_type === 2 &&
      bot_type &&
      (bot_type === 1 ? 'text' : bot_type === 2 ? 'img' : 'double'),
    [peer_type, bot_type]
  );
  const isChannelCourse = useMemo(() => chat! && isChatCourse(chat), [chat]);
  const isAdult = useMemo(() => chat! && isChatAdult(chat), [chat]);
  const title = chat! && getChatTitle(t, chat as ApiChat);
  const { toggleScreens } = useHistoryBack({});

  const handleClickChat = useLastCallback(() => {
    if (bot_type) {
      setActivePack(aiPrompts?.find((el) => el.bot_type === bot_type));
      toggleScreens({
        middleScreen: MiddleColumnContent.PaymentAi,
      });
    } else if (active) {
      openChat({ id: `-${peer_id}` });
    }
  });

  const handleLeaveChannel = useLastCallback(() => {
    selectSubscription({
      chatId: `-${peer_id}`,
      isActive: (active && !cancelled) || false,
      expire_at,
      bot_type: bot_type === 3 ? 7 : bot_type,
    });
  });

  return (
    <ListItem
      className='chat-item-clickable subscriptions-item underline'
      onClick={handleClickChat}
      isStatic={!active}
      ripple
      secondaryIcon='filled'
      contextActions={
        active && !cancelled
          ? [
              {
                title: t('Channel.CancelSubscription'),
                icon: 'close-outline',
                handler: handleLeaveChannel,
              },
            ]
          : [
              {
                title: t('Channel.Subscribe'),
                icon: 'fly-subscribe',
                handler: handleLeaveChannel,
              },
            ]
      }
    >
      <div className='status'>
        {aiName ? (
          <div className={`Avatar no-photo ai-${aiName}`}>
            <IconSvgPayment name={aiName} w='32' h='32' />
          </div>
        ) : (
          <Avatar peer={chat} />
        )}
      </div>
      <div className='info'>
        <div className='info-row'>
          <div
            className={classNames('type', cancelled ? 'cancelled' : 'active')}
          >
            <span className='icon-svg'>
              <IconSvg name={cancelled ? 'close' : 'check'} w='10' h='10' />
            </span>
            <span className='text'>{cancelled ? 'Cancelling' : 'Active'}</span>
          </div>
          {isChannelSubscription && (
            <span className='icon-svg'>
              <IconSvg name='channel' />
            </span>
          )}
          {isChannelCourse && <IconSvg name='online-course-outline' />}
          {isAdult && <IconSvg name='adult' />}
          <div className='title FullNameTitle-module__root'>
            <h3 dir='auto' className='fullName'>
              {aiName ? t(`PaymentAi.${aiName}.Title`) : title!}
            </h3>
          </div>
        </div>
        <div className='subtitle'>
          <span className='amount'>
            <span className='text-secondary'>Price:</span>
            <span className='price'>
              <IconSvg name='dollar' w='14' h='14' />
              {getMoneyFormat(amount, 2, 2) ?? 0}
            </span>
          </span>
          {expire_at && (
            <span>
              <span className='text-secondary'>Renews:</span>{' '}
              {formatDateToString(Number(expire_at) * 1000)}
            </span>
          )}
        </div>
      </div>
    </ListItem>
  );
};

export default memo(
  withGlobal<OwnProps>((global, { item: { peer_id } }): StateProps => {
    const chat = selectChat(global, `-${peer_id}`);

    if (!chat) {
      return {};
    }

    return {
      chat,
    };
  })(SettingsSubscriptionsItem)
);
