import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import IconSvgPayment from '../../../payment/IconSvgPayment';

import { IAiPurchases } from '../../../../global/types';
import { getActions, withGlobal } from '../../../../global';
import { useTranslation } from 'react-i18next';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import Button from '../../../ui/Button';
import useLastCallback from '../../../../hooks/useLastCallback';
import { MiddleColumnContent } from '../../../../types';
import ChatBot from '../../../left/main/ChatBot';
import Loading from '../../../ui/Loading';
import SearchInput from '../../../ui/SearchInput';
import useHistoryBack from '../../../../hooks/useHistoryBack';

import classNames from 'classnames';
import './AiSpace.scss';
import NothingFound from '../../../common/NothingFound';

const HIDE_LENGTCH = 5;

type StateProps = {
  aiPurchases?: IAiPurchases;
  botsList?: string[];
  categories?: string[];
};

const AiSpaceMain: FC<StateProps> = ({ aiPurchases, botsList, categories }) => {
  const { setAiPurchases, getBotList } = getActions();
  const { text_total = 0, img_total = 0 } = aiPurchases || {};

  const { t } = useTranslation();
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [categoriesList, setCategoriesList] = useState<string[]>();
  const [filter, setFilter] = useState<{ q?: string; categories?: string[] }>();
  const { toggleScreens } = useHistoryBack({});

  const handlePaymentAi = useLastCallback(() => {
    toggleScreens({
      middleScreen: MiddleColumnContent.PaymentAi,
    });
  });

  const updateFilter = useLastCallback((value?: string) => {
    setFilter({ ...filter, q: value?.toLowerCase() });
  });

  const handleClickMore = useLastCallback(() => {
    setShowAllCategory(!showAllCategory);
  });

  const handleSelectCategory = useCallback(
    (name: string) => {
      let { categories = [] } = filter || {};
      const idx = categories.findIndex((el) => el === name);
      idx === -1 ? categories.push(name) : categories.splice(idx, 1);
      setFilter({ ...filter, categories });
    },
    [filter]
  );

  const hadleClearFilter = useLastCallback(() => {
    setFilter({ ...filter, categories: [] });
  });

  useEffectOnce(() => {
    setAiPurchases();
  });

  useEffect(() => {
    if (showAllCategory) {
      setCategoriesList(categories);
    } else {
      setCategoriesList(categories?.slice(0, HIDE_LENGTCH));
    }
  }, [categories, showAllCategory]);

  return (
    <div className='settings-container ai-space'>
      <SearchInput value={filter?.q} onChange={updateFilter} />
      <div
        className={classNames('filter-group', { 'show-all': showAllCategory })}
      >
        <button
          type='button'
          onClick={hadleClearFilter}
          className={`Button without-icon ${
            !filter?.categories?.length && 'selected'
          }`}
        >
          All
        </button>
        {categoriesList?.map((name) => (
          <button
            type='button'
            onClick={() => handleSelectCategory(name)}
            className={`Button ${
              filter?.categories &&
              filter?.categories?.findIndex((el) => el === name) !== -1 &&
              'selected'
            }`}
          >
            {/* <i className={`icon ${name}`} /> */}
            {`${name[0].toUpperCase()}${name.slice(1)}`}
          </button>
        ))}

        {categories && categories.length > HIDE_LENGTCH && (
          <button
            type='button'
            onClick={handleClickMore}
            className='Button all-button'
          >
            More
            <i className={`css-icon-${showAllCategory ? 'up' : 'down'}`} />
          </button>
        )}
      </div>
      <div className='ai-banner'>
        <h3>Al Space: Your Gateway to Advanced Al bots</h3>
        <div className='description'>
          Discover a world of cutting-edge artificial intelligence at Al Space,
          your destination for state-of-the-art Al bots. It offers an extensive
          collection of specialized Al bots, each meticulously crafted to
          address diverse needs across multiple categories.
        </div>
        <dl>
          <dd>
            <span className='Avatar size-small no-photo ai-text'>
              <IconSvgPayment name='text' w='20' h='20' />
            </span>
            <span>{text_total}</span>
            {t('AI.Chat_prompts')}
          </dd>
          <dd>
            <span className='Avatar size-small no-photo ai-image'>
              <IconSvgPayment name='img' w='20' h='20' />
            </span>
            <span>{img_total}</span>
            {t('AI.Image_prompts')}
          </dd>
        </dl>
        <Button fullWidth size='smaller' onClick={handlePaymentAi}>
          Buy AI pack
        </Button>
      </div>
      <h3>AI Bots</h3>
      <div className='bots-list'>
        {botsList ? (
          botsList.length ? (
            botsList.map((botId) => <ChatBot key={botId} chatId={botId} />)
          ) : (
            <NothingFound />
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      aiPurchases,
      chats: { listIds },
      aiBots: { categories },
    } = global;
    const botsList = listIds.bots;
    return {
      aiPurchases,
      botsList,
      categories,
    };
  })(AiSpaceMain)
);
