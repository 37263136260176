import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalState } from '../../global/types';
import { getActions, withGlobal } from '../../global';
import { pick } from '../../util/iteratees';
import useInterval from '../../hooks/useInterval';
import Time from '../../util/Time';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import SafeImg from '../../assets/Safe.png';
import { ConfirmEmailType } from '../../types';
import useEffectWithPrevDeps from '../../hooks/useEffectWithPrevDeps';
import { Field, FormikProvider, useFormik } from 'formik';
import PasswordField from '../ui/Formik/PasswordField';
import * as Yup from 'yup';
import useHistoryBack from '../../hooks/useHistoryBack';
import Modal from '../ui/Modal';
import useLastCallback from '../../hooks/useLastCallback';
import Button from '../ui/Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

type OwnProps = {
  title?: string;
  text?: string;
  isActive?: boolean;
  setScreen?: () => void;
};

type StateProps = Pick<GlobalState, 'confirmData'>;

const VerifyEmailForm: FC<OwnProps & StateProps> = ({
  title,
  text,
  isActive,
  setScreen,
  confirmData,
}) => {
  const {
    confirmForgotPassword,
    confirmEmail,
    setAuthCode,
    setRegistrationCode,
    changeEmail,
    changePassword,
    deleteAccount,
    approveWithdraw,
    setAuthUsernameAndPassword,
    requestForgotPassword,
    resendConfirmationCode,
    getConfirmCodeFromChangeEmail,
    getConfirmCodeFromWithdraw,
    returnToAuthPassword,
    apiUpdate,
    goToRegistration,
    clearConfirmEmail,
  } = getActions();
  const {
    type,
    expire = 0,
    email,
    password,
    wallet_id,
    payment_id,
    error,
    params,
  } = confirmData! || {};

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(120);

  const [codeValue, setCodeValue] = useState('');
  const [numInput, setNumInput] = useState(0);
  const [localError, setLocalError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isHideResend =
    type &&
    ((type === ConfirmEmailType.confirmAuth && seconds) ||
      type === ConfirmEmailType.confirmEmail);

  const closeModal = useLastCallback(() => {
    setIsModalOpen(false);
    goToRegistration();
  });

  const handleGoBack = useCallback(() => {
    switch (type) {
      case ConfirmEmailType.forgotPassword:
        apiUpdate({
          '@type': 'updateAuthorizationState',
          authorizationState: 'authorizationStateWaitResetPassword',
        });
        break;
      case ConfirmEmailType.confirmAuth:
      case ConfirmEmailType.confirmUnauthorized:
        returnToAuthPassword();
        break;
      case ConfirmEmailType.confirmEmail:
        goToRegistration();
        break;
    }
    clearConfirmEmail();
  }, [type]);

  useHistoryBack({
    isActive,
    onBack: handleGoBack,
  });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const val =
        e.target.value === '' ? ' ' : e.target.value.replace(/[^\d]/g, '');

      if (val.length === 2) {
        setCodeValue(
          codeValue.slice(0, Number(name)) +
            val[0] +
            codeValue.slice(Number(name) + 1)
        );
      } else {
        const maxValue = setCodeValue(
          codeValue.slice(0, Number(name)) +
            val +
            codeValue.slice(Number(name) + val.length)
        );
      }

      setNumInput(+name + 1);
    },
    [codeValue]
  );

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().when([], {
      is: () => type === ConfirmEmailType.changePassword,
      then: () => Yup.string().required(t('Validation.password_required')),
    }),
    password: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{1,}$/,
        t('Validation.password_validation')
      )
      .required(t('Validation.password_required'))
      .min(6, t('Validation.password_min_length'))
      .max(15, t('Validation.password_max_length'))
      .notOneOf(
        [Yup.ref('currentPassword'), ''],
        t('Validation.new_password_matches')
      ),

    confirmPassword: Yup.string()
      .required(t('Validation.password_confirm'))
      .oneOf([Yup.ref('password'), ''], t('Validation.password_match')),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const name = (e.target as HTMLInputElement).name;
      if (
        e.key === 'Backspace' &&
        !(e.target as HTMLInputElement).value &&
        name !== '0'
      ) {
        setNumInput(+name - 1);
      }
    },
    [numInput]
  );

  const handleLogIn = useLastCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    if (password) {
      const token = await executeRecaptcha('login');
      setAuthUsernameAndPassword({ ...params, password, token });
    }
  });

  const handleResendEmail = useCallback(() => {
    switch (type) {
      case ConfirmEmailType.confirmAuth:
        handleLogIn();
        break;
      case ConfirmEmailType.forgotPassword:
        if (email && password) {
          requestForgotPassword({ email });
        }
        break;
      case ConfirmEmailType.confirmEmail:
        resendConfirmationCode({
          username_or_email: params.email,
          type,
        });
        break;
      case ConfirmEmailType.confirmUnauthorized:
        resendConfirmationCode({
          ...params,
          type,
        });
        break;
      case ConfirmEmailType.changePassword:
      case ConfirmEmailType.confirmChangeEmail:
        getConfirmCodeFromChangeEmail(params);
        break;
      case ConfirmEmailType.confirmWithdrawal:
        getConfirmCodeFromWithdraw(params);
    }
  }, [type, params]);

  useInterval(() => {
    if (expire && seconds > 0) {
      setSeconds(Time.getSecondsFromExpiry(expire * 1000, true));
    } else if (setScreen) {
      setScreen();
    }
  }, 1000);

  const debaunceRequest = useDebouncedCallback(
    () => {
      if (codeValue.replace(/ /g, '').length >= 6 && type) {
        if (seconds <= 0) {
          setLocalError(t('Errors.incorrect_code'));
          return;
        }

        if (!formik.isValid) return;

        const cutVal = codeValue.slice(0, 6);
        switch (type) {
          case ConfirmEmailType.confirmEmail:
            if (email) {
              setRegistrationCode({
                email,
                code: cutVal,
              });
            }
            break;
          case ConfirmEmailType.forgotPassword:
            if (email && formik.values.confirmPassword) {
              confirmForgotPassword({
                email,
                new_pass: formik.values.confirmPassword,
                code: cutVal,
              });
              setCodeValue('');
            }
            break;
          case ConfirmEmailType.confirmAuth:
            if (email) {
              setAuthCode({ code: cutVal, email });
            }
            break;
          case ConfirmEmailType.confirmUnauthorized:
            if (email) {
              confirmEmail({ code: cutVal, email });
            }
            break;
          case ConfirmEmailType.confirmChangeEmail:
            changeEmail({ new_email: email, code: cutVal });
            break;
          case ConfirmEmailType.changePassword:
            if (formik.isValid && formik.dirty) {
              changePassword({
                prev_pass: formik.values.currentPassword,
                new_pass: formik.values.password,
                code: cutVal,
              });
            }
            break;
          case ConfirmEmailType.DeleteAccount:
            deleteAccount({ code: cutVal });
            break;
          case ConfirmEmailType.confirmWithdrawal:
            if (wallet_id && payment_id) {
              approveWithdraw({
                wallet_id,
                payment_id,
                paypal_email: email || undefined,
                bank_withdraw_requisites_id:
                  params.bank_requisites_id || undefined,
                approve_code: cutVal,
              });
            }
            break;
        }
      }
    },
    [codeValue, type, formik.isValid],
    600,
    true
  );

  useEffect(() => {
    debaunceRequest();
  }, [codeValue, formik.isValid]);

  useEffect(() => {
    setCodeValue('');
    if (expire) {
      setSeconds(Time.getSecondsFromExpiry(expire * 1000, true));
    }
  }, [expire]);

  useEffectWithPrevDeps(
    ([prevNumInput]) => {
      if (prevNumInput === numInput) return;
      if (numInput < 6 && codeValue[numInput - 1] !== ' ') {
        const nextInput = document.querySelector<HTMLInputElement>(
          `[name="${numInput}"]`
        )!;
        nextInput.type = 'text';
        if (prevNumInput && prevNumInput > numInput) {
          nextInput.setSelectionRange(1, 1);
        } else {
          nextInput.setSelectionRange(0, 0);
        }
        nextInput.type = 'number';

        nextInput.focus();
      }
    },
    [numInput, codeValue]
  );

  useEffect(() => {
    if (seconds <= 0 && type && type === ConfirmEmailType.confirmEmail) {
      setIsModalOpen(true);
    }
  }, [type, seconds]);

  return (
    <div className='auth-form confirm-email'>
      {type === ConfirmEmailType.forgotPassword ||
      type === ConfirmEmailType.changePassword ? (
        <div className='auth-password-center auth-password-form'>
          <div>
            <h2 className='mb-3'>{t('ForgotPassword.CreatePasswordTitle')}</h2>
            <p className='text-muted'>
              {t('ForgotPassword.CreatePasswordText')}
            </p>
            <FormikProvider value={formik}>
              {type === ConfirmEmailType.changePassword && (
                <Field
                  name='currentPassword'
                  component={PasswordField}
                  label={t('Settings.CurrentPassword')}
                />
              )}
              <Field
                name='password'
                component={PasswordField}
                label={t('Registration.place_new_password')}
              />
              <Field
                name='confirmPassword'
                component={PasswordField}
                label={t('Registration.place_confirm_password')}
              />
            </FormikProvider>
          </div>
        </div>
      ) : (
        <img
          className='mb-3'
          src={SafeImg}
          alt=''
          loading='lazy'
          width='130'
          height='120'
        />
      )}
      {title && type !== ConfirmEmailType.forgotPassword ? (
        <h2 className='mb-2'>{title}</h2>
      ) : (
        <h3>{title}</h3>
      )}
      {text && <p className='text-muted mb-1'>{text}</p>}
      <p className='color-danger mb-0'>{t('VerifyEmail.Text_2')}</p>
      <div className='verifi-input-wrap'>
        {[...Array(6).keys()].map((_, idx) => {
          return (
            <input
              key={idx}
              name={String(idx)}
              value={codeValue[idx] || ''}
              className='form-control'
              type='number'
              inputMode='numeric'
              onInput={onChange}
              onKeyDown={onKeyDown}
              disabled={codeValue.length < idx}
            />
          );
        })}
        {(error || localError) && (
          <p className='input-notification--error'>{error || localError}</p>
        )}
      </div>

      <div className='verify-timer-wpapper'>
        <p>{t('ForgotPassword.VerificationRequest')}</p>
        <p className='verify-timer'>
          <span>{seconds}</span> <span className='text-muted'>seconds</span>
        </p>

        {!isHideResend && (
          <p>
            {t('ForgotPassword.VerificationReceive')}{' '}
            <span
              role='button'
              className='text-primary'
              onClick={handleResendEmail}
            >
              {t('Settings.ResendEmail')}
            </span>
          </p>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        className='error'
        title='Oops'
      >
        <div className='modal-content'>
          {t('Errors.verification_code_has_expired')}
          <div className='dialog-buttons mt-2'>
            <Button isText onClick={closeModal}>
              {t('OK')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => pick(global, ['confirmData']))(
    VerifyEmailForm
  )
);
