import React, { FC, memo, useEffect } from 'react';

import ListItem from '../../ui/ListItem';
import { getActions, withGlobal } from '../../../global';
import useLastCallback from '../../../hooks/useLastCallback';
import Avatar from '../../common/Avatar';
import { ApiChat, ApiUser } from '../../../api/types';
import { selectChat } from '../../../global/selectors';

import Skeleton from '../../ui/Skeleton';
import './Chat.scss';
import { BotItem } from '../../../types';

type OwnProps = {
  chatId: string;
};

type StateProps = {
  chat?: ApiChat;
  chatBot?: BotItem;
};

const ChatBot: FC<OwnProps & StateProps> = ({ chatId, chat, chatBot }) => {
  const { openChat } = getActions();
  const handleClick = useLastCallback(() => {
    if (chat) {
      openChat(
        { id: chatId, shouldReplaceHistory: true },
        { forceOnHeavyAnimation: true }
      );
    }
  });

  return (
    <ListItem
      ripple
      onClick={handleClick}
      rightElement={<span className='open-chat'>open</span>}
    >
      {chat ? (
        <>
          <div className='status'>
            <Avatar peer={chat} />
          </div>
          <div className='info'>
            <div className='info-row'>
              <div className='title FullNameTitle-module__root'>
                <h3 dir='auto' className='fullName'>
                  {chatBot?.first_name || chat?.title}
                </h3>
              </div>
            </div>
            <div className='subtitle text-secondary'>
              {chatBot?.description || chat.fullInfo?.about}
            </div>
          </div>
        </>
      ) : (
        <Skeleton height={57} />
      )}
    </ListItem>
  );
};

export default memo(
  withGlobal<OwnProps>((global, { chatId }): StateProps => {
    const chat = selectChat(global, chatId);
    const {
      aiBots: { botById },
    } = global;
    if (!chat || !botById) {
      return {};
    }
    const chatBot = botById.find(
      ({ bot_id }) => bot_id.toString() === chatId.toString()
    );
    return {
      chat,
      chatBot,
    };
  })(ChatBot)
);
