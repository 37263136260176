import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import SettingsSubscriptionsItem from './SettingsSubscriptionsItem';
import { getActions, withGlobal } from '../../../global';
import { AiPrompt, AiPurchaseType, GlobalState } from '../../../global/types';
import { pick } from '../../../util/iteratees';
import { useTranslation } from 'react-i18next';
import useLastCallback from '../../../hooks/useLastCallback';
import { formatDateToString } from '../../../util/dateFormat';

import DropdownMenu from '../../ui/DropdownMenu';
import MenuItem from '../../ui/MenuItem';
import IconSvg from '../../ui/IconSvg';
import Button from '../../ui/Button';
import ConfirmDialog from '../../ui/ConfirmDialog';

import classNames from 'classnames';

enum ETypeSubscription {
  current,
  previous,
}

type OwnProps = {
  setActivePack: (pack: AiPrompt | undefined) => void;
};

type StateProps = Pick<GlobalState, 'subscriptionsList' | 'aiPrompts'>;

const SettingsSubscriptions: FC<StateProps & OwnProps> = ({
  subscriptionsList,
  aiPrompts,
  setActivePack,
}) => {
  const {
    getPaidSubscriptions,
    leaveChannel,
    openChat,
    joinChannel,
    unSubscribeAi,
    subscribeAi,
  } = getActions();
  const { t } = useTranslation();
  const [type, setType] = useState(ETypeSubscription.current);
  const [selectChat, setSelectChat] = useState<{
    chatId: string;
    isActive: boolean;
    expire_at?: number;
    bot_type?: AiPurchaseType;
  }>();

  const SelectButton: FC<{ onTrigger: () => void; isOpen?: boolean }> =
    useMemo(() => {
      return ({ onTrigger, isOpen }) => (
        <Button
          isText
          color='translucent'
          className={classNames('no-style', { active: isOpen })}
          onClick={onTrigger}
        >
          {t(`Channel.TypeSubscription.${type}`)}

          <i className='css-icon-down' />
        </Button>
      );
    }, [type]);

  const handleLeaveChannel = useLastCallback(() => {
    if (selectChat) {
      const { isActive, bot_type, chatId } = selectChat;
      if (isActive) {
        if (bot_type) {
          unSubscribeAi({ sub_type: bot_type });
        } else {
          leaveChannel({ chatId, unsubscribe: true });
        }
      } else {
        if (bot_type) {
          subscribeAi({ sub_type: bot_type });
        } else {
          joinChannel({ chatId });
          openChat({ id: chatId });
        }
      }

      getPaidSubscriptions(type);
      setSelectChat(undefined);
    }
  });

  useEffect(() => {
    getPaidSubscriptions(type);
  }, [type]);

  return (
    <div className='settings-container'>
      <div className='settings-subscriptions'>
        <div className='type-select'>
          <DropdownMenu trigger={SelectButton}>
            <MenuItem
              customIcon={<IconSvg name='channel' w='25' h='25' />}
              onClick={() => setType(ETypeSubscription.current)}
            >
              {t(`Channel.TypeSubscription.${ETypeSubscription.current}`)}
            </MenuItem>
            <MenuItem
              customIcon={<IconSvg name='subscription' w='25' h='25' />}
              onClick={() => setType(ETypeSubscription.previous)}
            >
              {t(`Channel.TypeSubscription.${ETypeSubscription.previous}`)}
            </MenuItem>
          </DropdownMenu>
        </div>

        <div className='subscriptions-list'>
          {subscriptionsList[
            type === ETypeSubscription.current ? 'current' : 'previous'
          ]?.map((el) => (
            <SettingsSubscriptionsItem
              key={el.peer_id}
              item={el}
              selectSubscription={setSelectChat}
              setActivePack={setActivePack}
              aiPrompts={aiPrompts}
            />
          ))}
        </div>
      </div>
      <ConfirmDialog
        isOpen={Boolean(selectChat)}
        onClose={() => setSelectChat(undefined)}
        text={t(
          selectChat?.isActive
            ? 'Channel.UnsubscribeConfirmation.PaidChannel'
            : 'Channel.SubscribeConfirmation',
          {
            expire: selectChat?.expire_at
              ? formatDateToString(selectChat.expire_at * 1000)
              : undefined,
          }
        )}
        confirmLabel={t(
          selectChat?.isActive ? 'Channel.Unsubscribe' : 'Confirm'
        )}
        confirmHandler={handleLeaveChannel}
        confirmIsDestructive
      />
    </div>
  );
};

export default memo(
  withGlobal(
    (global): StateProps => pick(global, ['subscriptionsList', 'aiPrompts'])
  )(SettingsSubscriptions)
);
