import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../ui/Button';
import { useTranslation } from 'react-i18next';
import renderText from '../../common/helpers/renderText';
import useLastCallback from '../../../hooks/useLastCallback';
import useHistoryBack from '../../../hooks/useHistoryBack';
import {
  LeftColumnContent,
  MiddleColumnContent,
  SettingsScreens,
} from '../../../types';
import { getActions } from '../../../global';
import { LANDING_HOSTNAME, SUPPORT_USER_ID } from '../../../config';

const SLIDES = [
  'social',
  'tik-tok',
  'secure',
  'ai-space',
  'channel',
  'course',
  'group',
];

const WelcomeEllo: FC = () => {
  const { t } = useTranslation();

  const { toggleLeftColumn, openChat, openUrl } = getActions();

  const { toggleScreens } = useHistoryBack({});

  const handleClick = useLastCallback((name: string) => {
    switch (name) {
      case 'ai-space':
        toggleScreens({
          leftScreen: LeftColumnContent.Settings,
          middleScreen: MiddleColumnContent.Settings,
          settingScreen: SettingsScreens.aiSpace,
        });
        toggleLeftColumn();
        break;
      case 'channel':
        toggleScreens({ leftScreen: LeftColumnContent.NewChannelSelect });
        break;
      case 'group':
        toggleScreens({ leftScreen: LeftColumnContent.NewGroupStep1 });
        break;
      case 'course':
        toggleScreens({ leftScreen: LeftColumnContent.NewCoursSelect });
        break;
      case 'help':
        openChat({ id: SUPPORT_USER_ID });
        break;
      case 'secure':
        openUrl({ url: `${LANDING_HOSTNAME}/privacy-policy` });
    }
  });

  return (
    <div className='welcome-swiper'>
      <h3 className='text-center'>Welcome to Ello</h3>
      <Swiper
        loop
        centeredSlides={true}
        spaceBetween={8}
        slidesPerView={'auto'}
      >
        {SLIDES.map((name) => (
          <SwiperSlide>
            <div className='item-wrap'>
              <div className={`item-bg ${name}`}>
                <div className='title'>
                  <span>{t(`Welcome.${name}-title`)}</span>
                  <Button onClick={() => handleClick(name)}>OPEN</Button>
                </div>
              </div>

              <div className='description custom-scroll'>
                {t(`Welcome.${name}-content`).includes('\n') ? (
                  <ul>{renderText(t(`Welcome.${name}-content`), ['ul'])}</ul>
                ) : (
                  t(`Welcome.${name}-content`)
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WelcomeEllo;
